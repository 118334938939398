<template>
  <div v-if="activeAccount" class="sidebar" :class="{ 'sidebar--small': isSmall, 'locale-usa': locale === 'USA' }">
    <div class="sidebar__balance">
      <div class="sidebar__balance-title">
        {{ $t('sidebar.balance') }}, <span>{{ $t('currency.currency_name') }}</span>
      </div>
      <div class="sidebar__balance-value">
        {{ activeAccount.balance ? locale==='USA'? numberWithSpacesFix2(activeAccount.balance): numberWithSpacesCeil(activeAccount.balance).split('.')[0] : 0 }} <span>{{
          $t('currency.currency_symbol')
        }}</span>
      </div>
      <!--      <div v-if="!isSmall" class="sidebar__balance-increase">-->
      <!--        + 34 987 вчера-->
      <!--      </div>-->
      <div class="sidebar__balance-add" :class="{ 'locale-ru': locale === 'RU', 'locale-usa': locale === 'USA' }">
        <!-- <ButtonElement :text="$t('button_props.add')" @click-button="openPrepaidDialog" :disabled="role==='user'&&locale === 'USA'?true:false"/> -->
        <!-- <ButtonElement :text="user.agent? $t('button_props.get_points') : $t('button_props.add')" @click-button="!user.agent ? openPrepaidDialog() : getPoints()" /> -->
        <ButtonElement :text="$t('button_props.add')" @click-button="openPrepaidDialog" :disabled="isAddBallance"/>
      </div>
      <div class="sidebar__balance-out" @click="openDepositDialog"
        :class="{ 'locale-ru': locale === 'RU', 'locale-usa': locale === 'USA' }">
        {{ $t('sidebar.pay_for_advertising') }}
      </div>
    </div>
    <div v-if="showCashbackSidebar" class="sidebar__cashback">
      <div class="sidebar__cashback-period">
        <div class="sidebar__cashback-period__info" :class="{ 'sidebar__cashback-period__info--open': showOptions }">
          {{ $t('sidebar.cashback') }}, {{ $t('currency.currency_name') }}
          <!--          <img src="@/assets/icons/icon-arrow-blue.svg" alt="arrow">-->
        </div>
      </div>
      <div v-if="activeAccount" class="sidebar__cashback-value">
        {{ activeAccount.cashback_balance ? locale==='USA'? numberWithSpacesFix2(activeAccount.cashback_balance): numberWithSpacesCeil(activeAccount.cashback_balance) : 0 }} <span>{{
          $t('currency.currency_symbol') }}</span>
      </div>
      <div v-if="role === 'finance'" class="sidebar__cashback-add">
        <ButtonElement :text="$t('button_props.change')" view="empty" @click-button="openCashbackDialog" />
      </div>
    </div>
    <!-- Promo code -->
    <div v-if="activeAccount.promocodes && activeAccount.promocodes.length" class="sidebar__promo-code">
      <div class="sidebar__promo-period">
        <div class="sidebar__promo-period__info">
          {{ $t('sidebar.promo') }}, {{ $t('currency.currency_name') }}
          <Tooltip 
           :data="promoDescription"/>
        </div>
      </div>
      <div v-if="activeAccount.promocodes &&activeAccount.promocodes.length" class="sidebar__promo-value">
        {{ numberWithSpacesCeil(activeAccount.promocodes[0].sum )}} <span>{{
          $t('currency.currency_symbol') }}</span>
      </div>
      <div class="sidebar__promo-expired">
       {{$t('sidebar.promo_expired')}} {{reverseDate(formatDate(activeAccount.promocodes[0].expired_at))}}
      </div>
    </div>
     <!-- End of the promo code -->
    <div v-if="role !=='partner'" class="add-platforms sidebar__add-platforms">
      <div class="add-platforms__title">
        {{ $t('sidebar.budget') }}
      </div>
      <!-- This field should be removed to platformBallance -->
      <ul v-if="temporaryPlatformBallance">
        <li v-for="(item, index) of temporaryPlatformBallance" :key="index">
          <div class="platform-wallets" v-if="item.wallets">
            <div class="platform-wallets__wallet" v-for="(value, key) in item.wallets" :key="key">
              <span>{{ `${item.name} ${Object.keys(item.wallets).length > 1 ? `(${key})` : ''}` }}</span>
              <div class="amount-wrapper">
                <span>{{locale==='USA'? numberWithSpacesFix2(value) : numberWithSpacesCeil(value) }}</span>
                <span class="currency">{{ currency_symbols[key] }}</span>
                <!-- <button class="add-button">
                  <img src="../../assets/icons/icon-cross-button-small.svg" alt="cross button">
                </button> -->
              </div>
            </div>
          </div>
          <div class="platform-ballance" v-else>
            <span>{{ item.name }}</span>
            <div class="amount-wrapper">
              <span>{{locale==='USA'? numberWithSpacesFix2(item.total_balance) : numberWithSpacesCeil(item.total_balance) }}</span>
              <span class="currency">{{ item.slug!= 'google' ? $t('currency.currency_symbol') : '$' }}</span>
              <!-- <button class="add-button">
                <img src="../../assets/icons/icon-cross-button-small.svg" alt="cross button">
              </button> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import checkCashback from '@/mixin/checkCashback'
import numberWithSpacesCeil from '@/mixin/numberWithSpaces'
import numberWithSpacesCeilFix2 from '@/mixin/numberWithSpaces'
import Tooltip from '../elements/Tooltip.vue'


export default {
  name: 'ReferralSidebar',
  components: {
    ButtonElement,
    Tooltip
  },
  props: {
    isSmall: {
      type: Boolean,
      default: false
    },
    isAddBallance: {
      type: Boolean,
      default: true
    }
  },
  mixins: [checkCashback, numberWithSpacesCeil, numberWithSpacesCeilFix2],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      selectedPeriod: null,
      showOptions: false,
      currency_symbols: {
        USD: '$',
        PLN: 'zł',
        RUB: '₽',
        EUR: '€',
      }
    }
  },
  computed: {
    promoDescription() {
      if(this.activeAccount.promocodes && this.activeAccount.promocodes.length) {
        return this.activeAccount.promocodes[0].description
      }
      else {
        return ''
      }
       
    },

    platformBallance() {
      const platforms = this.$store.state.Ad.platformsList || [];
      const services = this.$store.state.Auth.platformBallance || {};
      const visibleServices = this.$store.state.Auth.activeAccount.visible_services || {};
      const platFormList = platforms.reduce((initial, item)=>{
        const obj = { name:item.name, slug: item.slug, statistics: item.statistics }
        initial.push(obj)
        return initial
      },[])
      // const dataArray = this.platformList
      const resultArray = platFormList.reduce((acc, item) => {
        const service = services[item.slug];
        const visibility = visibleServices[item.slug];
        if (service) {
          item.total_balance = service.balance.total_balance? service.balance.total_balance: 0;
          // item.wallets = Object.keys(service.balance.wallets ?? {}).length  ? service.balance.wallets : {};
          item.wallets = this.filterNonEmptyValues(service.balance.wallets) 
          item.isVisible =  visibility
        }
        acc.push(item);
        return acc;
      }, []);
      const filteredArray = resultArray.filter(item => item.isVisible )
      return filteredArray
    },

    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    statistic() {
      return this.$store.state.Ad.statistic
    },
    role() {
      return this.$store.state.Auth.role
    },
    temporaryPlatformBallance() {
      // return this.platformBallance.filter(item => item.slug !== 'telegram' && item.statistics)
      return this.platformBallance.filter(item => item.statistics)
    },
    user() {
      return this.$store.state.Auth.user
    }, 
  },
  beforeDestroy() {
    this.$store.commit('Ad/setData', { label: 'statistic', data: null })
  },
  methods: {
    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    },
    reverseDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;

      return formattedDate;
    },

    openPrepaidDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Prepaid' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openDepositDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Deposit' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    openCashbackDialog() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Cashback' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getBallance() {
      this.$store.dispatch('Auth/getBallance', this.activeAccount.id)
    },
    filterNonEmptyValues(wallets) {//check empty keys in object
      const result = {};
      let hasTruthyValue = false;
      for (const key in wallets) {
        if (wallets[key]) {
          result[key] = wallets[key];
          hasTruthyValue = true;
        }
      }
      return hasTruthyValue ? result : false;
    }, 
    getPoints() {
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Points' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    }
  },
  mounted() {
    // console.log(this.activeAccount)
    this.getBallance()
      document.addEventListener('click', (e) => {
        if (!e.target.closest('.sidebar__cashback-period')) {
          this.showOptions = false
        }
      }, true)
  },

  watch: {
    activeAccount() {
      this.getBallance()
    }
  }

}
</script>

<style lang="scss">
@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';

.tooltip-width {
  min-width: 300px;
}

.sidebar {
  &__add-platforms {
    margin-top: 2.4rem;
  }
  // promo code
  &__promo {
    &-code {
      color:#9B9FA8;
      padding: 2.5rem 0 2.5rem;
      margin-top: 2.5rem;
      border-bottom: 1px solid #D1D4D8;
      border-top: 1px solid #D1D4D8;
      font-family: 'Roboto';
    }
    &-expired {
      margin-top: 1.2rem;
    }
    &-period {
      margin-bottom: 1.2rem;
      position: relative;
      white-space: nowrap;
      &__info {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.9rem;
        .tooltip-wrapper {
          position: static;
         
        }
      }
    }
    &-value {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 2.9rem;
    }
  }
  //end of the promo code

  .add-platforms {
    &__title {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
    }

    ul {
      margin-top: 1.4rem;

      li {
        list-style: none;
        color: #7F858D;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 0.8rem 0 0.8rem 0;
        border-bottom: 1px solid #EDEFF3;

        .amount-wrapper {
          display: flex;
          align-items: center;

          .currency {
            margin-left: 0.5rem;
          }
        }
        .platform-wallets {
          &__wallet {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .platform-ballance {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        // .add-button {
        //   background: none;
        //   border: none;
        //   display: flex;
        //   margin-left: 0.8rem;
        // }
      }
    }
  }


  &.locale-usa {
    font-family: 'Gilroy';
    font-style: normal;
    color: var(--black);

    .sidebar {
      &__balance-title {
        color: var(--black);
        font-weight: 500;
        margin-bottom: 0.6rem;
      }

      &__balance-value {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 2.8rem;
        margin-bottom: 3rem;
      }

      &__cashback-period {
        margin-bottom: 0.8rem;

        &__info {
          color: var(--black);
          font-weight: 500;
          font-size: 1.6rem;
        }
      }

      &__cashback-value {
        font-weight: 600;
        font-size: 2.8rem;
      }
    }

  }

  filter: drop-shadow(0px 5px 10px rgba(158, 158, 158, 0.1));
  background: $basicWhite;
  border-radius: 6px;
  padding: 2.5rem;

  &--small {
    width: 28.5rem;

    .sidebar {
      &__balance {
        filter: none;
        padding: 2.5rem;
        margin-bottom: 3rem;
        border: 1px solid #E7E9F1;

        &-title {
          margin-bottom: 0.6rem;
          color: $lightGrey;
        }

        &-value {
          color: $basicBlack;
          margin-bottom: 1.5rem;
          font-size: 2.5rem;
          line-height: 2.9rem;
        }

        &-add {
          .button {
            height: 3.5rem;
          }
        }
      }

      &__cashback {
        padding-left: 2.5rem;

        &-period {
          margin-bottom: 0.8rem;

          &__info {
            font-size: 1.8rem;
            line-height: 2.1rem;

            img {
              width: 2.5rem;
            }
          }
        }

        &-value {
          margin-bottom: 0.3rem;
        }
      }
    }
  }

  &__balance {
    border-bottom: 1px solid $gray4;
    padding: 0 0 1.6rem;

    &-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000000;
    }

    &-value {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 2.9rem;
      color: $basicBlack;
      margin-bottom: 1rem;
    }

    &-increase {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $lightGrey;
      margin-bottom: 3rem;
    }

    &-add {
      width: 100%;
      margin-bottom: 1rem;

      &.locale-usa {
        .button {
          font-size: 1.8rem;
          padding-top: 1.7rem;
          padding-bottom: 1.7rem;
          height: auto;
        }
      }

      .button {
        width: 100%;
        font-weight: 400;
        height: 4.5rem;
      }
    }

    &-out {
      text-align: center;
      cursor: pointer;

      &.locale-ru {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $gray2;
        transition: 0.3s ease-out;

        &:hover {
          color: #000;
        }
      }

      &.locale-usa {
        font-size: 1.8rem;
        font-weight: 500;
        color: var(--primary);
        padding: 1.7rem 4.2rem;
        border: 3px solid var(--primary);
        border-radius: 8px;
      }


    }
  }

  &__cashback {
    position: relative;
    padding: 2.5rem 0 0;

    &.disabled {
      opacity: 0.3;

      &::after {
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
      }
    }

    &-period {
      margin-bottom: 1.2rem;
      position: relative;
      white-space: nowrap;

      &__options {
        position: absolute;
        width: 100%;
        background: $basicWhite;
        top: calc(100% + 1rem);
        left: 0;

        &-item {
          padding: 1rem 2rem;
          cursor: pointer;
          transition: 0.3s ease-out;

          &:hover {
            background: $primary;
            color: $basicWhite;
          }
        }
      }

      &__info {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000000;

        &--open {
          img {
            transform: rotate(180deg);
          }
        }

        span {
          color: $primary;
          margin: 0 0.5rem 0 1rem;
        }

        img {
          width: 3rem;
          transition: 0.3s ease-out;
        }
      }
    }

    &-value {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 2.9rem;
    }

    &-info {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $lightGrey;
    }

    &-add {
      width: 100%;
      margin-top: 1rem;

      .button {
        width: 100%;
        height: 4.5rem;
      }
    }
  }

  @include below(993px) {
    padding: 16px;
  }

  @include below(769px) {
    padding: 16px;

    &__balance {

      &-title {
        font-size: 14px;
        line-height: 1.4;
      }

      &-value {
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 8px;
      }

      &-add {
        margin-bottom: 8px;

        .button {
          font-size: 14px;
          line-height: 1.4;
          padding: 8px 12px;
          height: auto;
        }
      }

      &-out {
        font-size: 14px;
        line-height: 1.4;
      }
    }

    &__cashback {
      padding-top: 16px;

      &-period {
        margin-bottom: 8px;

        &__info {
          font-size: 14px;
          line-height: 1.4;
        }
      }

      &-value {
        font-size: 20px;
        line-height: 1.4;
      }
    }
  }
}
</style>
